<template>
  <div class="about">
    <s-header :hasBack="true" :title="'通知编辑'" />

    <div class="pageContent">
      <div class="P-grade">
        <h3>年级</h3>
        <ul>
          <li
            v-for="(item, index) in gradeList"
            :key="index"
            @click="p_grade(item.grade_id)"
            :class="infoData.grade_id == item.grade_id ? 'click' : ''"
          >
            {{ item.gradeName }}
          </li>
        </ul>
      </div>
      
      <div class="P-class" v-if="classList.length > 0">
        <h3>班级</h3>
        <ul>
          <li
            v-for="(item, index) in classList"
            :key="index"
            @click="p_class(item.class_id.toString())"
            :class="infoData.include_class.indexOf(item.class_id.toString()) >= 0 ? 'click' : ''"
          >
            {{ item.className }}
          </li>
        </ul>
      </div>
      
      <h3>标题</h3>
      <div class="title">
        <van-form>
          <van-field
            v-model="infoData.title"
            placeholder="请填写通知标题"
          ></van-field>
        </van-form>
      </div>
      
      <h3>通知内容</h3>
      <div style="border: 1px solid #ccc; margin-top: 10px">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 300px; overflow-y: hidden"
          v-model="infoData.contents"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreated"
        />
      </div>
      
      <div class="P-bar">
        <div>
          <div class="reset" @click="toDo('save')">保存</div>
          <div class="confirm" @click="toDo('post')">发布</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { onBeforeMount, ref, shallowRef, defineExpose, onBeforeUnmount, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import sHeader from "@/components/SimpleHeader";
import { apiGetTeacherInfo, apiTeacherToSave, apiTeacherToPost } from "@/service/notice";
import { Dialog, Toast } from "vant";

import {
  apiGetGrade,
  apiGetClassList,
} from "@/service/teacher1.3.js";

const router = useRouter();
const route = useRoute();

let notice_id = ref(0);
onBeforeMount(async () => {
  if(typeof(route.query.notice_id) !== 'undefined'){
    notice_id.value = parseInt(route.query.notice_id)
  }
  
  await getGradeList();
  
  if(notice_id.value){
    await getInfo();
  }
});

let infoData = ref({
  title: '',
  contents: '',
  grade_id: 0,
  include_class: [],
  
  type: 0,
  publish_objects: 1
});
const getInfo = async () => {
  await apiGetTeacherInfo({
    id: notice_id.value
  }).then((res) => {
    infoData.value.title = res.data.title ? res.data.title : '';
    infoData.value.contents = res.data.contents ? res.data.contents : '';
    infoData.value.grade_id = res.data.grade_id ? res.data.grade_id : 0;
    infoData.value.include_class = res.data.include_class ? res.data.include_class.split(',') : [];
    
    if(infoData.value.grade_id){
      // 获取班
      getClassList(infoData.value.grade_id);
    }
  })
}

let gradeList = ref([]);
const getGradeList = async () => {
  let res = await apiGetGrade().then((res) => {
    gradeList.value = res.data;
  });
};

let classList = ref([]);
const getClassList = async (gradeId) => {
  let res = await apiGetClassList({
    grade_id: gradeId
  }).then((res) => {
    classList.value = res.data;
  });
};

const p_grade = async (grade_id) => {
  if(grade_id != infoData.value.grade_id){
    infoData.value.grade_id = grade_id
    await getClassList(grade_id);
    infoData.value.include_class = [];
  }else{
    infoData.value.grade_id = 0;
    infoData.value.include_class = [];
    classList.value = [];
  }
}

const p_class = async (class_id) => {
  if(infoData.value.include_class.indexOf(class_id) >= 0){
    let index = infoData.value.include_class.indexOf(class_id)
    infoData.value.include_class.splice(index, 1)
  }else{
    infoData.value.include_class.push(class_id)
  }
}

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();
let mode = ref("default");

// 富文本配置
const toolbarConfig = {
  toolbarKeys: [
    "bold", // 加粗
    "italic", // 斜体
    // "through", // 删除线
    "underline", // 下划线
    "bulletedList", // 无序列表
    "numberedList", // 有序列表
    "color", // 文字颜色
    // "insertLink", // 插入链接
    // "fontSize", // 字体大小
    // "lineHeight", // 行高
    "delIndent", // 缩进
    "indent", // 增进
    // "deleteImage", //删除图片
    "divider", // 分割线
    "uploadImage", // 上传图片
    // "insertImage", // 网络图片
    "insertTable", // 插入表格
    "justifyLeft", // 左对齐
    "justifyJustify", // 两端对齐
    "justifyCenter", // 居中对齐
    "justifyRight", // 右对齐
    "undo", // 撤销
    "redo", // 重做
    // "clearStyle", // 清除格式
    "fullScreen", // 全屏
  ],
};

const editorConfig = { placeholder: "请输入内容...", MENU_CONF: {} };
editorConfig.MENU_CONF["uploadImage"] = {
  base64LimitSize: 999999999 * 1024,
};

const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
};

onMounted(() => {
  const editor = editorRef.value;
});

defineExpose({ editorRef });

const toDo = async (type) => {
  if(!infoData.value.grade_id){
    Toast.fail("请选择发布的年级");
    return false;
  }
  if(infoData.value.title == ''){
    Toast.fail("请输入通知标题");
    return false;
  }
  if(infoData.value.contents == ''){
    Toast.fail("请输入通知内容");
    return false;
  }
  
  if(infoData.value.include_class.length < 1){
    Dialog.confirm({
      title: "确定发布对象为全年级？",
      message: "",
    })
    .then(async () => {
      await toSave();
      
      if(type == 'post'){
        await toPost();
      }
    })
    .catch(() => {
      // on cancel
    });
  }else{
    await toSave();
    
    if(type == 'post'){
      await toPost();
    }
  }
}

const toSave = async () => {
  let formData = new FormData();
  if(notice_id.value){
    formData.append('id', notice_id.value);
  }
  formData.append('type', infoData.value.type);
  formData.append('title', infoData.value.title);
  formData.append('contents', infoData.value.contents);
  formData.append('publish_objects', infoData.value.publish_objects);
  formData.append('grade_id', infoData.value.grade_id);
  formData.append('include_class', infoData.value.include_class.toString());
  
  await apiTeacherToSave(formData).then((res) => {
    notice_id.value = res.data.notice_id
    Toast.success('保存成功')
  })
}

const toPost = async () => {
  Dialog.confirm({
    title: "确定发布？",
    message: "发布后无法再次修改",
  })
  .then(async () => {
    let formData = new FormData()
    formData.append('id', notice_id.value);
    await apiTeacherToPost(formData).then((res) => {
      if(res.code === 1){
        Toast.success(res.msg);
        router.go(-1);
      }
    })
  })
  
}
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

.pageContent{
  width: 80%;
  padding: 20px;
  margin: 20px auto;
  background-color: #fff;
}

.P-grade,
.P-class{
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      background-color: #eee;
      padding: 5px 10px;
      border-radius: 3px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .click {
      background-color: #18a4e0;
      color: #fff;
    }
  }
}

.title{
  border: 1px solid #ccc;
}

.P-bar {
    padding-top: 40px;
    display: flex;
  }
  .P-bar > div {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #18a4e0;
    border: 1px solid #18a4e0;
    box-sizing: border-box;
    overflow: hidden;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    div {
      width: 48%;
      color: #18a4e0;
    }
    div:nth-child(1) {
      background-color: #fff;
      border-radius: 0 0 20px 0;
    }
    div:nth-child(2) {
      color: #fff;
    }
  }
</style>
